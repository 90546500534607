<template>

    <AvalonEditor
        v-if="editor || previewMode"
        :alias="alias"
        :page="pageAlias"
        :course="course"
        :news-alias="newsAlias"
    />
    <template-loader
        v-else-if="!editor"
        :alias="alias"
        :page-alias="pageAlias"
        :news-alias="newsAlias"
        :document-check="documentCheck"
        :course="course"
    />


</template>

<script>
import {mapState} from "vuex";
export default {
  name: "AvalonLoader",
  props : {
    alias : {
      type : String
    },
    pageAlias : {
      type : String
    },
    newsAlias : {
      type : String
    },
    editor : {
      type : Boolean
    },
    course : {
      type : String
    },
    documentCheck : {
      type :Boolean,
      default : false
    }
  },
  computed : {
    ...mapState('avalon' , ['previewMode'])
  },
  components : {
    TemplateLoader : () => import("@/components/templates/TemplateLoader"),
    AvalonEditor : () => import("@/components/AvalonEditor/AvalonEditor"),
  },
  beforeMount() {
    if ( this.$route.path.includes('document/check')) {
      this.documentCheck = true
    }
  }
}
</script>

<style scoped>

</style>